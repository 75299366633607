import React from 'react';

import AboutSection from '../components/about-section';
import Layout from '../components/layout';
import HeroSection from '../components/hero-section';
import BuildingCarousel from '../components/building-carousel';
import { buildings } from '../data/keso-invest-u-izgradnji.json';

export default () => (
  <Layout>
    <HeroSection cn="invest">
      <h2>Prodaja stambeno-poslovnih prostora</h2>
    </HeroSection>
    <AboutSection>
      <p>
        Kompanija Keso Invest d.o.o. posluje sa ciljem da obezbjedi investiranje
        i gradnju stambenog i poslovnog prostora vrhunskog kvaliteta na
        teritoriji grada Novog Sada.
      </p>
      <p>
        Djelatnost preduzeća je isključivo izgradnja i prodaja stanova i
        poslovnog prostora.
      </p>
      <p>Za više informacija možete nas pozvati na broj +381 64 6579 655.</p>
    </AboutSection>

    <section className="pb-0">
      <div className="container">
        {buildings.map(building => (
          <div key={`zgrada-${building.key}`}>
            <BuildingCarousel
              name={building.key}
              description={building.description}
              location={building.location}
              images={building.images}
            />
          </div>
        ))}
      </div>
    </section>
  </Layout>
);
