import React, { Component } from 'react';
import Siema from 'siema';

import styles from '../styles/carousel.module.css';
import { cx } from '../utils';

export default class Carousel extends Component {
  selector = `${this.props.name}-siema`;

  componentDidMount() {
    this.siema = new Siema({
      selector: `.${this.selector}`,
      loop: true,
    });
  }

  prev = () => {
    this.siema.prev();
  };

  next = () => {
    this.siema.next();
  };

  generateCarousel = () => {
    const { images } = this.props;
    const imagesLength = images.length;

    return this.props.images.map((img, index) => (
      <div className={cx("row", styles.imgWrapper)} key={`slika-${index}`}>
        <img
          className={cx("col-lg-2 d-none d-lg-block", styles.img__left)}
          src={images[(index + imagesLength - 1) % imagesLength]}
          alt="Stambeno-poslovni objekat"
        />
        <img
          className="col-md-10 col-lg-7"
          src={img}
          alt="Stambeno-poslovni objekat"
        />
        <img
          className={cx("col-md-2 col-lg-3 d-none d-md-block", styles.img__right)}
          src={images[(index + 1) % imagesLength]}
          alt="Stambeno-poslovni objekat"
        />
      </div>
    ));
  };

  render() {
    const { description, location } = this.props;

    return (
      <>
        <div className="position-relative row">
          <div className="col-12 col-md-10 pb-3">
            <p className={styles.description} dangerouslySetInnerHTML={{ __html: description }}></p>
          </div>

          <div className="col-10 col-md-12">
            <div className="informations">
              <div className={styles.dataPill}>
                <span className="icon-location"></span>
                <span>{location}</span>
              </div>
            </div>
          </div>

          <div className={cx(styles.navigationButtons, styles.navigationButtonsBottom)}>
            <button onClick={this.prev}>
              <span className="icon-arrow-left"></span>
            </button>
            <button onClick={this.next}>
              <span className="icon-arrow-right"></span>
            </button>
          </div>
        </div>

        <div className={cx(this.selector, styles.carousel)}>
          {this.generateCarousel()}
        </div>
      </>
    );
  }
}
